<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>指派任务</span>
        <el-button @click="add" class="btn" type="primary" size="mini" :disabled="roleId == 4">新增任务</el-button>
      </div>
      <!-- <div class="search">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="所属区域">
            <el-select size="small" v-model="formInline.facilityId" clearable placeholder="">
              <el-option
                v-for="item in sheshi"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div> -->
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="specificTaskCode" label="任务ID"></el-table-column>
          <el-table-column prop="specificTaskName" label="任务名称"></el-table-column>
          <el-table-column prop="taskDescribe" label="任务描述"></el-table-column>
          <el-table-column prop="waterMeterCode" label="水表编号"></el-table-column>
          <el-table-column prop="userCode" label="用户编号"></el-table-column>
          <el-table-column prop="staffName" label="外勤人员"></el-table-column>
           <el-table-column prop="endDate" label="截止日期">
            <template slot-scope="scope">
              {{ scope.row.endDate ? dayjs(scope.row.endDate).format('YYYY-MM-DD'):'' }}
            </template>
          </el-table-column>
          <el-table-column prop="creator" label="创建人"></el-table-column>
          <el-table-column prop="createTime" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == 0" size="small" type="warning">未完成</el-tag>
              <el-tag v-else-if="scope.row.status == 1" size="small" type="success">已完成</el-tag>
              <el-tag v-else-if="scope.row.status == 2" size="small" type="danger">超时完成</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="result" label="结果">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.result == 0" size="small" type="warning">未解决</el-tag>
              <el-tag v-else-if="scope.row.result == 1" size="small" type="success">已解决</el-tag>
              <el-tag v-else-if="scope.row.result == 2" size="small" type="danger">误报</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="source" label="来源">
            <template slot-scope="scope">
              <span v-if="scope.row.source == 0">新创建</span>
              <span v-else-if="scope.row.source == 1">巡查</span>
              <span v-else-if="scope.row.source == 2">故障</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-dropdown v-if="staffId == scope.row.creatorId" placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                  <div v-show="scope.row.result == 0">
                    <el-dropdown-item :disabled="roleId == 4"><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                  </div>
                  <el-dropdown-item :disabled="roleId == 4"><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown v-else placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-dis">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown"></el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {},
      tableData: [],
      staffId: 0,
      roleId: 0,
    }
  },
  methods: {
    dayjs,
    onOff(row) {
      this.$ajax.post("ruleStatus", {
        id: row.id,
        status: row.status == 0 ? 1 : 0
      }).then((res) => {
        this.loadTableData();
      });
    },
    onSubmit() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      this.loadTableData()
    },
    details(row) {
      this.$router.push({ name: 'directionalTaskDetail', params: {row: row}})
    },
    edit(row) {
      this.$router.push({ name: 'directionalTaskEdit', params: {row: row}})
    },
    dlt(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax.post("specificDelete",{}, {
          specificTaskCode: row.specificTaskCode,
        }).then((res) => {
          this.loadTableData();
        });
      });
    },
    add() {
      this.$router.push('/directionalTask/add')
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadTableData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadTableData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    loadTableData() {
      this.loading = true
      this.$ajax.post('specificQuery', {
        pageNum: this.page,
        pageSize: this.size,
      }).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.loadTableData()
    this.staffId = sessionStorage.getItem('id')
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    // sessionStorage.removeItem('currentPage')
    // sessionStorage.removeItem('currentSize')
  }
}
</script>

<style lang="less" scoped>
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
</style>